import { gsap } from 'gsap';

export default function LandingLoader(timing){
  let target = $('*[data-barba-introductionscreen]');

  if(target){
    //gsap.set(target, {autoAlpha: 1});

  }
  this.init = function(){
    if(target){
        console.log("=================>",target);
      let fade = gsap.timeline( {
        onComplete: function(){
          appEvent.trigger('app:transitionGlobal:end');
          $(target).remove();

        }
      });
      fade.to('[data-barba-introductionscreen]',.6,
           {
              autoAlpha:0,
              ease:'power2.inOut',
             
         });



    }
    else{
      return false;
    }
  }
}
