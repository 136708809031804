/***************************************************
██╗    ██╗██╗██████╗ ██╗ ██████╗ ██████╗ ███╗   ███╗
██║    ██║██║██╔══██╗██║██╔════╝██╔═══██╗████╗ ████║
██║ █╗ ██║██║██████╔╝██║██║     ██║   ██║██╔████╔██║
██║███╗██║██║██╔══██╗██║██║     ██║   ██║██║╚██╔╝██║
╚███╔███╔╝██║██████╔╝██║╚██████╗╚██████╔╝██║ ╚═╝ ██║
 ╚══╝╚══╝ ╚═╝╚═════╝ ╚═╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝
***************************************************/


import barbaRouter from '@barba/router';
import barba from '@barba/core';
/**************************************************/
window.barbaInstance = barba;
window.barbaRouter = barbaRouter;
/**************************************************/
import './transitions/default.js';

/**************************************************/
import Router from "./router.js";
window.appRouter = new Router();
/**************************************************/

// app event
import AppEventComponent from './components/AppEventComponent';
window.appEvent = new AppEventComponent();

// form
import FormComponent from './components/FormComponent';
window.formComponent = new FormComponent();

import FormMessage from './views/formMessage.js';
window.formMessage = new FormMessage();


/**************************************************/
import 'parsleyjs';
import 'parsleyjs/dist/i18n/fr.js';
import ImageLoader from './components/ImageLoaderComponent'
window.imageLoader = new ImageLoader();
/**************************************************/
import LandingLoader from './views/landingLoader.js'
import imageReplace from "./views/imageReplace.js";
window.landingLoader = new LandingLoader(.5);
/************************************************/





export default function Barba(){
  window.transitions= [];
  this.instance = window.barbaInstance;
  this.instance.use(window.appRouter, {
    routes,
  });
  this.addCustomIntro = function(introduction){
      window.landingLoader = introduction;
  }
  this.setDefaultTransition = function(transitionObject){
    if (transitionObject){
      window.defaultTransition = transitionObject;

    }
    else{
      window.defaultTransition = window.barbaDefaultTransition;
    }
    window.transitions.push(window.defaultTransition)
  }
  this.addTransition = function(transitionObject, ){
    window.transitions.push(transitionObject);
  }


  this.init =  function(){
    //window.imageLoader.lazy();
    imageReplace($('main section:last-child'));
    window.initGlobal();
    window.formComponent.initGlobal();
    window.formComponent.initView();
    window.formMessage.init();
    window.landingLoader.init();
    // ADD CONFIG ON HISTORY CHANGE
    (function(history){
        var pushState = history.pushState;
        history.pushState = function(state) {
            if (typeof history.onpushstate == "function") {
                history.onpushstate({state: state});
            }
            return pushState.apply(history, arguments);
        };
    })(window.history);
    window.onpopstate = history.onpushstate = function(e) {
        $(document).trigger('app:barba:historyChange');

    }
    this.instance.hooks.beforeLeave((data) => {
      $(document).trigger('app:barba:isChanging');
        if (data.trigger == "back" || data.trigger == "forward"){
          return;
        }
        localStorage.setItem('windowTop', window.pageYOffset);
        localStorage.setItem('windowTopGlobalScrol', window.globalScroll.offset);
    })

    this.instance.hooks.beforeEnter((data) => {
      //window.imageLoader.lazy();
      $(document).trigger('app:barba:beforeEnter');
      imageReplace($('main section:last-child'));
      let inlineScripts = data.next.container.querySelectorAll('script');
      inlineScripts.forEach(script => eval(script.innerHTML));
      window.initView();
      window.formMessage.init();
      window.formComponent.initView();
      document.body.id = typeof data.next.namespace !== 'undefined' ? data.next.namespace : '';
      history.scrollRestoration = 'manual';
    });

    this.instance.hooks.enter((data) => {
      $(document).trigger('app:barba:isLoaded');
    });
    this.instance.hooks.after((data) => {

      if (data.trigger == "back" || data.trigger == "forward"){
        window.scrollTo(0, localStorage.getItem('windowTop'));
        if (window.globalScroll){
          window.globalScroll.scrollTo(parseInt(localStorage.getItem('windowTopGlobalScrol')),0);
        }
      }
      else {

        window.scrollTo(0, 0);
        if (window.globalScroll){
          window.globalScroll.initView();
        }
      }

    });
    this.instance.init({
      views: window.views,
      debug: true,
      cacheIgnore: true,
      prefetchIgnore: true,
      prevent:(el) => {
          if(el.href == document.location.href)
          {
              el.event.preventDefault();
              return false;
          }
        },

      transitions: window.transitions,
      timeout:10000
    });
  }
}
