
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
window.scrollAnimation = new(function() {
    this.scroller = false;

    this.degrees_to_radians = function(degrees) {
        var pi = Math.PI;
        return degrees * (pi/180);
    }
    this.scroller = false;
    this.init = function(){

        gsap.registerPlugin(ScrollTrigger);
        // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll
        // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
        if (Modernizr.touch != true &&  window.globalScroll.smoothScroll ){
            window.globalScroll.smoothScroll.on('scroll', ScrollTrigger.update);
            this.scroller = $('[data-scroll-container]')[0];

        }else{
            $(document).on('app:scroll', ScrollTrigger.update);
            console.log('ici');
            this.scroller = $('#global')[0];


        }
        // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
        ScrollTrigger.scrollerProxy("[data-scroll-container]", {
          scrollTop(value) {
            return arguments.length ? window.globalScroll.scrollTo(value, 0, 0) : window.globalScroll.offset;
          }, // we don't have to define a scrollLeft because we're only scrolling vertically.
          getBoundingClientRect() {
            return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
          },
          // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
          pinType: document.querySelector("[data-scroll-container]").style.transform ? "transform" : "fixed"
        });


        // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
        ScrollTrigger.addEventListener("refresh", () => this.launch);
        ScrollTrigger.refresh();


    }
    this.initGlobal = function(){
      $(document).on('app:scroll',function(e,offset){
        console.log('offset',offset);
        if (offset > 100){
          $('.main-header__top').find('.drop-down').addClass('scroll-down');
        }else{
          $('.main-header__top').find('.drop-down').removeClass('scroll-down')
        }
      })
      
      

    }

    this.initView = function(){
      $('[data-barba-namespace]:last-child').find('.cut-lines').each((i,el)=>{
        gsap.to($(el).find('span')[0],{
          height:'100%',
          ease:'none',
          scrollTrigger:{
            trigger: $(el)[0],
            scroller: this.scroller,
            scrub:true,
            start: 'top bottom+=20px',
            end: 'bottom top',
          }
        })
      })
      $('[data-barba-namespace]:last-child').find('[data-pagineted-section]').each((i,el)=>{
        console.log( $('[data-barba-namespace]:last-child').find('[data-pagineted-section]').length)
        ScrollTrigger.create({
          trigger: el,
          scroller: this.scroller,
          start:'top center',

          invalidateOnRefresh:true,
          onEnter: ()=>{
            console.log(i);
            appEvent.trigger('app:scrollSection',[i]);
          },
          onEnterBack: ()=>{
            appEvent.trigger('app:scrollSection',[i]);

          }
        
        });
      })
      if ($('[data-barba-namespace]:last-child').find('[data-scroll-section]').length){
        let timout = false;
        let timing = 600;
        let target = $('[data-barba-namespace]:last-child').find('[data-scrollto]').eq(0).attr('href')
        /*$(window).off('mousewheel').on('mousewheel',function(e){
          console.log('mousewheel',e.originalEvent.wheelDelta);
          if (e.originalEvent.wheelDelta/120 > 0){
            if ( $(target).offset().top  >= 0 ){
              window.globalScroll.scrollTo($('[data-barba-namespace]:last-child').find('[data-scroll-section]'),timing)

            }
          }else{
            if (window.globalScroll.y < $(target).offset().top  ){
              if (timout){
                window.clearTimeout(timout)
              }
              timout=window.setTimeout(function(){
                window.globalScroll.scrollTo(target,timing)
                timout = window.setTimeout(function(){
                  gsap.killTweensOf(window.globalScroll.scroll)
  
                },timing)
  
              },1)
  
            }
           
          }
          
        })*/
        gsap.to('[data-barba-namespace]:last-child .home-heading h1',{
          opacity:0,
          filter:'blur(10px)',
          ease:'power1.inOut',
          
          scrollTrigger:{
            trigger: '[data-barba-namespace]:last-child .home-heading',
            scroller: this.scroller,
            scrub:true,
            start: 'top top',
            //snap:{snapTo:.75,duration:.3,delay:0,ease:"power1.out"},

          }
        })
        ScrollTrigger.create({
          trigger: '[data-barba-namespace]:last-child .home-heading',
          scroller: this.scroller,
          pin:'[data-barba-namespace]:last-child .home-heading h1',
          pinType:'fixed',
          endTrigger: 'footer',

        
        });
      }
      if ($('[data-barba-namespace]:last-child').find('.pagination-section')){
        
        ScrollTrigger.create({
          trigger: '[data-barba-namespace]:last-child .pagination-section-wrapper',
          scroller: this.scroller,
          pinSpacing:false,
          pin:'[data-barba-namespace]:last-child .pagination-section',
          pinType:'fixed',
          start:'top center-=' + $('[data-barba-namespace]:last-child').find('.pagination-section').children().innerHeight() / 2,
          end:'bottom bottom'
        });
      }
     
     
      

    }
    

    
    
    
   
    this.lazyload = function(){
        gsap.utils.toArray("[data-barba-namespace]:last-child .container").forEach((section, i) => {


          ScrollTrigger.create({
            trigger: section,
            start: "-50% bottom",
            scroller: this.scroller,
            once: true,

            onEnter: () => {
                window.imageLoader.lazy(section).then(function(res){
                    console.log(res,$(section).hasClass('container--horizontal'));

                });
            },
            onEnterBack: () =>{
                window.imageLoader.lazy(section).then(function(res){

                });
            }
          });
        });
    }
   
    this.update = function(){
        ScrollTrigger.refresh(true);
        window.globalScroll.updateView();

    }
});
