import { gsap } from 'gsap';
export default function ScreenLoader (timingIn, timingOut){
 // gsap.set($('*[data-barba-loadingscreen]'), {autoAlpha: 0});
  this.show = function(){
    return new Promise(function(resolve){
      let tl = gsap.timeline( {
        repeat:0,
        paused: true,
        onComplete: function(){
          console.log('on complete resolving')
          resolve(true);

        }
      });

      $('*[data-barba-loadingscreen]').addClass('active')
      gsap.to($('*[data-barba-loadingscreen] .line'), .4,{height:'150%',ease:"power2.In"});
      tl.fromTo($('*[data-barba-loadingscreen],*[data-barba-loadingscreen] img,*[data-barba-loadingscreen] svg'), timingIn,
        {autoAlpha:0,ease:"power2.in"},
        {autoAlpha:1,ease:"power2.in"});
      
        tl.play();
    });
  }
  this.hide =  function(){
      return new Promise(function(resolve){
        

        let tl = gsap.timeline( {
	          repeat:0,
	          paused: true,
	          onComplete: function(){
            console.log('on complete resolving');
            resolve(true);
                $('*[data-barba-loadingscreen]').removeClass('active')

    		}
        });
        tl.fromTo($('*[data-barba-loadingscreen]'),
          {autoAlpha:1,ease:"power2.out"},
          {autoAlpha:0,ease:"power2.out",duration:timingOut});
          gsap.to($('*[data-barba-loadingscreen] .line'), .4,{height:'0%',ease:"power2.out",onComplete:function(){
            tl.play();
  
          }});
      });
  }
}
