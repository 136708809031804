import BarbaJs from './_barba/barba.js';
import FancyComponent from "./components/FancyComponent";
import ScrollComponent from "./_barba/components/ScrollComponent";

window.initGlobal = function() {
    $('.main-nav').append($('.language-switcher').clone());
    if (window.navigator.userAgent.match(/MSIE|Trident/) !== null){
        $('body').addClass('is-ie');
    }
    $('body').on('click',function(e){
        //$('.drop-down').removeClass('open');
    })
    $('body').on('click','.drop-down .btn',function(){
        $(this).parent().toggleClass('open');
    })
    
    window.menuNav.init();
    if (Modernizr.touch === true) {
        $('body').addClass('is-touch').removeClass('is-desktop');
        try { // prevent exception on browsers not supporting DOM styleSheets properly
            for (var si in document.styleSheets) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;

                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;

                    if (styleSheet.rules[ri].selectorText.match(':hover')) {
                        styleSheet.deleteRule(ri);
                    }
                }
            }
        } catch (ex) {}
    }else{
        $('body').addClass('is-desktop').removeClass('is-touch');
    }


    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.appEvent.resize(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--header-height', `${$('.main-header__top').outerHeight()}px`);
    }, true).trigger('resize');

    window.fancy = new FancyComponent();
    window.globalScroll = new ScrollComponent({
        target:'#global',
        offset:'.main-header__top'
    });
    window.scrollAnimation.init();


    window.scrollAnimation.initGlobal();
    window.scrollAnimation.initView();
    window.scrollAnimation.lazyload();

    $(document).off('app:transition:isEnter').on('app:transition:isEnter',function(){
        window.menuNav.reset();
        window.scrollAnimation.initView();
        window.scrollAnimation.lazyload();


       
    });

   
    $(document).off('form:newsletter_email:success',newsletterFooterHandler).on('form:newsletter_email:success',newsletterFooterHandler)

     function newsletterFooterHandler(data, response,el){
        $(el).find('input[type="email"]').val('');
        $(el).find('input[type="checkbox"]').prop('checked',false);
        $.fancybox.open({
            src  : $('#succesNewsletter'),
            type : 'inline',
            opts : {
                animationDuration : 600,
                animationEffect   : 'slide-in-out',
            }
        })

    }

};

window.initView = function() {
    window.fancy.initView();
    window.menuNav.reset();
    window.menuNav.updateCurrentLink();
};
$( document ).ready(function() {
    console.log( "ready!" );

});

$(window).on('load', function(){

    window.barba = new BarbaJs();
    window.barba.setDefaultTransition();
    window.barba.setDefaultTransition();
    window.barba.init();
    $("body").addClass('loaded');
});
