
export default function FetchContent(res){

    return new Promise(function(resolve){
      let getUrl = window.location;
      let baseUrl = getUrl .protocol + "//" + getUrl.host + base;


      import(/*webpackPreload: true */ /* webpackChunkName: "[request]" */ `./controllers/${res}`).then(() => {
       console.log("Loaded");
       resolve(true)
      }, (err)=>{
          console.log("Error", err)
          //window.location.reload()
      })

    })

}
