import { gsap } from 'gsap';
import SwiperItem from '../views/swiperComponent.js';
import imageReplace from "../_barba/views/imageReplace.js";

window.menuNav = new(function() {
   
   this.init = function() {


    imageReplace($('header'));

      $('body').on('click','.scroll-indicator', function(){
          console.log('HELLO');
          window.globalScroll.scrollTo($(window).height(),800);
      })
    

      $('.btn-menu').on('click', function(e) {


          e.preventDefault();
          if ($(this).hasClass('open')){
            window.menuNav.hide();
          } else {
            window.menuNav.show();
          }
          $(this).toggleClass('open');


      })
      $('.nav-list__item a').on('click', function (e) {
        if($(this).hasClass('current'))
        {
            $('.main-header .btn-menu').trigger('click');
            e.preventDefault();
            return false;
        }
    });


  }

  this.show = function(){
    $('.main-nav').addClass('open');
    $('.drop-down').removeClass('open');

      gsap.to('.main-nav',.8,{
          opacity:1,
          ease:'power4.inOut'
      })
      gsap.fromTo('.nav-list__item a',.8,{
        ease: "power2.out",
        opacity:0,
        stagger: 0.09,
        yPercent: 150,
        //z:.1,

        },
        {
          ease: "power2.out",
          opacity:1,
          yPercent: 0,
          stagger: 0.09,
         // z:.1,

        }
    );




  }
  this.hide = function(){
    $('.main-nav').removeClass('open');
     gsap.to('.main-nav',.8,{
          opacity:0,
          ease:'power4.inOut'

     })

  }

  this.reset = function(){
      $('.main-nav').removeClass('open');
      $(".btn-menu").removeClass('open');
      $('.drop-down').removeClass('open');
      gsap.set('.main-nav',{opacity:0});


  }
  this.updateCurrentLink = function(){

      $('.nav-list__item a').removeClass('current');
      $('.nav-list__item a').removeClass('current');
      $('.nav-list__item a').each(function(index){
          let elHref = $(this).prop("href")
          if ( elHref  == document.location.href ){
              $(this).next().addClass('hover')
              $(this).addClass('current');
              let index = $(this).parents('.nav-list__item').index();
              $(this).parents('.nav-list__item').addClass('current')
          }

      });
    
     
  }


});
